import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import gsap from "gsap"

import Layout from "components/layout"
import SEO from "components/seo"

import { Cover } from "components/fostive/cover/cover"
import { Loader } from "components/anti/loader/loader"

import { replaceUnicode } from "src/utils/mixins"

// Images
import bgSample from "assets/img/bg/bg-home.jpg"
// import vidSample from "assets/video/video_cover.mp4"

// import { findBlockGql } from "src/utils/graphql/mixins"

const HomePage = () => {
  const rawData = useStaticQuery(graphql`
    query Home {
      wordPress {
        page(id: "home", idType: URI) {
          home {
            title
            backgroundType
            video {
              mediaItemUrl
            }
            image {
              sourceUrl
            }
            youtubeUrl
          }
        }
      }
    }
  `)
  const [coverProps, setCoverProps] = useState({})

  // useEffect(() => {
  //   gsap.from(".cover-title span", {
  //     y: 100,
  //     ease: "expo.inOut",
  //     duration: 2,
  //     delay: -1,
  //     stagger: { amount: 0.1 },
  //   })
  // }, [])

  // const blocks = rawData.wordPress.pageBy.blocks
  // console.log(blocks)
  // const acfCoverMainVideo = blocks.find(
  //   item => item.name === "acf/cover-main-video"
  // )
  // const youtubeEmbed = blocks.find(item => item.name === "core-embed/youtube")

  const home = rawData.wordPress.page.home
  const { title, backgroundType, video, image, youtubeUrl } = home

  useEffect(() => {
    if (backgroundType === "image") {
      setCoverProps(prevProps => ({ ...prevProps, img: image?.sourceUrl }))
    } else {
      setCoverProps(prevProps => ({
        ...prevProps,
        videoType: "video",
        videoAudio: true,
        videoSrc: video?.mediaItemUrl,
        youtubeSrc: youtubeUrl,
      }))
    }
    console.log(coverProps)
  }, [title, backgroundType, video, image, youtubeUrl])

  return (
    <>
      <Layout footer={false}>
        <SEO title="Home" />
        <Loader />
        <Cover
          variant="basic"
          theme="dark"
          title={title}
          // titleClassName="font-size-xl"
          titleCustom="home"
          // img={image?.sourceUrl}
          imgHeight="h-vh-100"
          className="cover-full cover-main text-center"
          contentMaxWidth="mw-100"
          // videoType="video"
          // videoAudio={true}
          // videoSrc={vidSample}
          // videoSrc={backgroundType === "video" ? video?.sourceUrl : null}
          // youtubeSrc={youtubeEmbed.attributes.url}
          {...coverProps}
        />
      </Layout>
    </>
  )
}

export default HomePage
