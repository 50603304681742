import React, { useEffect } from "react"
import { gsap } from "gsap"

import imgBrandDark from "../../../assets/img/brand/logo_anti-light.png"
import imgBrandLight from "../../../assets/img/brand/logo_fostive_white.png"

export const Loader = () => {
  // prettier-ignore
  useEffect(() => {
    gsap
      .timeline()
      .to(".loader .brand", 0, { css: { visibility: "visible" } })
      .from(".loader .brand", 1, { autoAlpha: 0, delay: 1 })
      .to(".loader .brand", 1, { autoAlpha: 0, ease: "expo.inOut" })
      .to(".loader", 1, { height: 0, ease: "expo.inOut" })
  }, [])

  return (
    <>
      {/* <div className="loader light">
        <img src={imgBrandBlack} className="brand img-fluid" alt="Antikode" />
      </div> */}
      <div className="loader dark">
        <img src={imgBrandLight} className="brand img-fluid" alt="Antikode" />
      </div>
    </>
  )
}
